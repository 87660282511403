// extracted by mini-css-extract-plugin
export var spacing = "IntroText-module--spacing--I0af0";
export var title = "IntroText-module--title--HfUTC";
export var items = "IntroText-module--items--vRLsB";
export var item = "IntroText-module--item--8jB7B";
export var initialized = "IntroText-module--initialized--G7J3V";
export var active = "IntroText-module--active--BrW4B";
export var word = "IntroText-module--word--dbu0n";
export var char = "IntroText-module--char--awD0X";
export var charActive = "IntroText-module--charActive--Jmsp0";
export var placeholder = "IntroText-module--placeholder--FS8ir";
export var devices = "IntroText-module--devices--v31eY";
export var wrapper = "IntroText-module--wrapper--kX7cN";
export var frame = "IntroText-module--frame--HhQ6D";
export var isPhone = "IntroText-module--isPhone--CCNw1";
export var isDesktop = "IntroText-module--isDesktop--RnMCp";
export var phone = "IntroText-module--phone--3ghgR";
export var phoneLine = "IntroText-module--phoneLine--uZAwr";
export var tablet = "IntroText-module--tablet--lEY1T";
export var tabletLine = "IntroText-module--tabletLine--a0DQI";
export var isTablet = "IntroText-module--isTablet--U1Uyu";
export var desktop = "IntroText-module--desktop--5j3AH";
export var desktopLine = "IntroText-module--desktopLine--ZdtrS";
export var line1 = "IntroText-module--line1--YlhY-";
export var line2 = "IntroText-module--line2--ih5Me";