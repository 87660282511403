// extracted by mini-css-extract-plugin
export var wrapper = "VideoControls-module--wrapper--TeNeJ";
export var isVisible = "VideoControls-module--isVisible--u3YJ3";
export var controls = "VideoControls-module--controls--Ed0a0";
export var controlsContainer = "VideoControls-module--controlsContainer--WZ8sM";
export var controlButton = "VideoControls-module--controlButton--41EMW";
export var time = "VideoControls-module--time--KGRIG";
export var seekProgress = "VideoControls-module--seekProgress--gwagz";
export var seek = "VideoControls-module--seek--C8cJR";
export var progress = "VideoControls-module--progress--l8alD";
export var loaded = "VideoControls-module--loaded--QECtK";